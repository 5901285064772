<template>
  <a-row :gutter="25">
    <div class="card w-100" v-if="eventForm.generalInfo.type != 'benefits' && eventForm.generalInfo.type != 'other'">
      <FormValidationWrap>
        <VerticalFormStyleWrap>
          <a-form name="sDash_validation-form" ref="formRef" :model="formState" :rules="rules" :layout="dataDOM.layout">
            <a-row :gutter="25">
              <a-col :xxl="12" :lg="12" :md="24" :xs="24">
                <a-col :xxl="24" :lg="24" :md="24" :xs="24">
                  <h3 class="form-font title">¿A quién va dirigido el evento?</h3>
                </a-col>
                <a-col :xxl="24" :lg="24" :md="24" :xs="24">
                  <a-form-item ref="administrativos" name="administrativos" class="form-font mx-3">
                    <a-checkbox
                      class="check-event"
                      v-on:change="onChangeAimedAt('admin_can_register')"
                      v-model:checked="formState.administrativos"
                    >
                      Personal en centro de trabajo administrativo
                    </a-checkbox>
                  </a-form-item>
                </a-col>
                <a-col :xxl="24" :lg="24" :md="24" :xs="24">
                  <a-form-item ref="educativos" name="educativos" class="form-font mx-3">
                    <a-checkbox
                      class="check-event"
                      v-on:change="onChangeAimedAt('edu_can_register')"
                      v-model:checked="formState.educativos"
                    >
                      Estructura educativa
                    </a-checkbox>
                  </a-form-item>
                </a-col>
                <a-col :xxl="24" :lg="24" :md="24" :xs="24">
                  <a-form-item ref="alumnos" name="alumnos" class="form-font mx-3">
                    <a-checkbox
                      class="check-event"
                      v-model:checked="formState.alumnos"
                      v-on:change="onChangeAimedAt('student_can_register')"
                      :disabled="formState.administrative_area_ids.length"
                    >
                      Estudiantes
                    </a-checkbox>
                  </a-form-item>
                </a-col>
                <a-col :xxl="24" :lg="24" :md="24" :xs="24">
                  <a-form-item ref="ciudadanos" name="ciudadanos" class="form-font mx-3">
                    <a-checkbox
                      class="check-event"
                      v-model:checked="formState.ciudadanos"
                      v-on:change="onChangeAimedAt('citizen_can_register')"
                      :disabled="
                        formState.administrative_area_ids.length || formState.work_center_ids.length ? true : false
                      "
                    >
                      Ciudadanos
                    </a-checkbox>
                  </a-form-item>
                </a-col>
                <a-col :xxl="24" :lg="24" :md="24" :xs="24" v-if="dataDOM.showMessageError">
                  <span class="title-normal" style="color:red;">Este campo es requerido</span>
                </a-col>
              </a-col>
              <a-col :xxl="12" :lg="12" :md="24" :xs="24">
                <a-col :xxl="24" :lg="24" :md="24" :xs="24">
                  <a-form-item
                    ref="administrative_area_ids"
                    :name="
                      formState.ciudadanos || formState.alumnos ? '' : 'administrative_area_ids'
                    "
                    :label="
                      formState.ciudadanos || formState.alumnos
                        ? 'Áreas administrativas que participan'
                        : 'Áreas administrativas que participan *'
                    "
                    class="form-font mx-3"
                  >
                    <a-select
                      name="administrative_area_ids "
                      class="title-normal"
                      mode="multiple"
                      style="width: 100%"
                      placeholder="Seleccione opciones"
                      @change="handleChangeMultiSelect($event, 'administrative_area_ids')"
                      v-model:value="formState.administrative_area_ids"
                      :disabled="formState.ciudadanos || formState.alumnos"
                      :optionFilterProp="'children'"
                      show-search
                      :filterOption="filterOptionSelect"
                    >
                      <a-select-option class="title-normal" :value="0">
                        Todas las áreas/centros de trabajo pueden participar
                      </a-select-option>
                      <a-select-option
                        class="title-normal option-break-spaces"
                        v-for="area in dataDOM.administrativesAreas"
                        :key="area.id_arbol"
                        :value="area.id_arbol"
                      >
                        {{}} {{ area.nombre }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :xxl="24" :lg="24" :md="24" :xs="24">
                  <a-form-item
                    ref="work_center_ids"
                    :name="formState.ciudadanos ? '' : 'work_center_ids'"
                    :label="
                      formState.ciudadanos
                        ? 'Centros de trabajo que participan'
                        : 'Centros de trabajo que participan *'
                    "
                    class="form-font mx-3"
                  >
                    <a-select
                      class="title-normal"
                      name="work_center_ids "
                      mode="multiple"
                      style="width: 100%"
                      placeholder="Seleccione opciones"
                      @change="handleChangeMultiSelect($event, 'work_center_ids')"
                      v-model:value="formState.work_center_ids"
                      :disabled="formState.ciudadanos"
                      :optionFilterProp="'children'"
                      show-search
                      :filterOption="filterOptionSelect"
                    >
                      <a-select-option class="title-normal" :value="0">
                        Todas las áreas/centros de trabajo pueden participar
                      </a-select-option>
                      <a-select-option
                        class="title-normal option-break-spaces"
                        v-for="workCenter in dataDOM.workCenters"
                        :key="workCenter.id"
                        :value="workCenter.id"
                      >
                      {{workCenter.clavecct}} {{  workCenter.nombre }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
              </a-col>
            </a-row>
          </a-form>
        </VerticalFormStyleWrap>
      </FormValidationWrap>
    </div>
  </a-row>
</template>
<script>
import { reactive, ref } from 'vue';
import { getAdministrativeAreas, getWorkCenter } from '../../../../services/events';

const EventAimedAt = {
  name: 'EventAimedAt',
  props: {
    eventForm: Object,
    sendForm: Boolean,
    isEdit: Boolean,
  },
  watch: {
    sendForm: function() {
      this.handleSendFormEvent();
    },
  },
  emits: ['getFormValues', 'saveDataNewEvent'],
  mounted() {
    if (this.isEdit) {
      this.formState.administrativos = this.eventForm.admin_can_register === 1 ? true : false;
      this.formState.educativos = this.eventForm.edu_can_register === 1 ? true : false;
      this.formState.alumnos = this.eventForm.student_can_register === 1 ? true : false;
      this.formState.ciudadanos = this.eventForm.citizen_can_register === 1 ? true : false;
      this.formState.administrative_area_ids = this.eventForm.administrative_area_ids;
      this.formState.work_center_ids = this.eventForm.work_center_ids;
    }
  },
  async setup(props, { emit }) {
    const formRef = ref();

    const getAdministrativeAreasFunc = async () => {
      var areas = [];
      await getAdministrativeAreas()
        .then(response => {
          if (response.success) {
            areas = response.data;
          }
        })
        .catch(() => {
          //console.log(error)
        });
      return areas;
    };

    const getWorkCenterFunc = async () => {
      var workCenter = [];
      await getWorkCenter()
        .then(response => {
          if (response.success) {
            workCenter = response.data;
          }
        })
        .catch(() => {
          //console.log(error)
        });
      return workCenter;
    };

    const dataDOM = reactive({
      layout: 'vertical',
      administrativesAreas: await getAdministrativeAreasFunc(),
      workCenters: await getWorkCenterFunc(),
      showMessageError: false,
    });

    const formState = reactive({
      administrativos: false,
      educativos: false,
      alumnos: false,
      ciudadanos: false,
      administrative_area_ids: [],
      work_center_ids: [],
    });
    const validateAreasAndCenters = async () => {
      console.log("Validate");
      try {
        console.log(await formRef.value.validateFields(['work_center_ids','administrative_area_ids']));
      } catch (error) {
        console.log(error);
      }
      console.log("End validate")
      return Promise.resolve();
    };
    const rules = {
      administrativos: [
        {
          validator: validateAreasAndCenters,
          required: false,
          trigger: 'change',
          type: 'boolean',
        },
      ],
      educativos: [
        {
          validator: validateAreasAndCenters,
          required: false,
          trigger: 'change',
          type: 'boolean',
        },
      ],
      ciudadanos: [
        {
          // validator: async () => {
          //   formRef.value.clearValidate(['work_center_ids','administrative_area_ids']);
          //   return Promise.resolve();
          // },
          validator: validateAreasAndCenters,
          required: false,
          trigger: 'change',
          type: 'boolean',
        },
      ],
      alumnos: [
        {
          validator: validateAreasAndCenters,
          required: false,
          trigger: 'change',
          type: 'boolean',
        },
      ],
      administrative_area_ids: [
        {
          validator: async (_, value) => {
            console.log("Validator admnistrative areas")
            const isRequired = (props.eventForm.generalInfo.type !== 'benefits' && props.eventForm.generalInfo.type !== 'other') &&
              (!formState.ciudadanos && !formState.alumnos);
            return (value && !Object.keys(value).length && isRequired)
              ? Promise.reject('Este campo es requerido')
              : Promise.resolve();
          },
          // required:
          //   props.eventForm.generalInfo.type == 'benefits' || props.eventForm.generalInfo.type == 'other'
          //     ? false
          //     : true,
          trigger: 'blur',
          type: 'array',
          message: 'Este campo es requerido',
        },
      ],
      work_center_ids: [
        {
          validator: async (_, value) => {
            console.log("Validator work centers");
            const isRequired = props.eventForm.generalInfo.type !== 'benefits' &&
                                props.eventForm.generalInfo.type !== 'other' &&
                                !formState.ciudadanos;
            return (value && !Object.keys(value).length && isRequired)
              ? Promise.reject('Este campo es requerido')
              :  Promise.resolve()
          },
          // required:
          //   props.eventForm.generalInfo.type == 'benefits' || props.eventForm.generalInfo.type == 'other'
          //     ? false
          //     : true,
          trigger: 'blur',
          type: 'array',
          message: 'Este campo es requerido',
        },
      ],
    };

    const onChangeAimedAt = field => {
      switch (field) {
        case 'admin_can_register':
          props.eventForm.admin_can_register = !props.eventForm.admin_can_register;
          if (formState.administrativos) {
            formState.administrative_area_ids = [0];
            handleChangeMultiSelect(formState.administrative_area_ids, 'administrative_area_ids');
            formState.work_center_ids = [0];
            handleChangeMultiSelect(formState.work_center_ids, 'work_center_ids');
            props.eventForm.student_can_register = false;
            formState.alumnos = false;
            props.eventForm.citizen_can_register = false;
            formState.ciudadanos = false;
          } else {
            const index_administrative_area_ids = formState.administrative_area_ids.findIndex(element => element === 0);
            formState.administrative_area_ids.splice(index_administrative_area_ids, 1);
            handleChangeMultiSelect(formState.administrative_area_ids, 'administrative_area_ids');
            const index_work_center_ids = formState.work_center_ids.findIndex(element => element === 0);
            formState.work_center_ids.splice(index_work_center_ids, 1);
            handleChangeMultiSelect(formState.work_center_ids, 'work_center_ids');
          }
          props.eventForm.registration.director_register = 0;
          props.eventForm.registration.admin_area_register = 0;
          break;
        case 'edu_can_register':
          props.eventForm.edu_can_register = !props.eventForm.edu_can_register;
          props.eventForm.registration.director_register = 0;
          props.eventForm.registration.admin_area_register = 0;
          break;
        case 'student_can_register':
          props.eventForm.student_can_register = !props.eventForm.student_can_register;
          props.eventForm.registration.director_register = 0;
          props.eventForm.registration.admin_area_register = 0;
          break;
        case 'citizen_can_register':
          props.eventForm.citizen_can_register = !props.eventForm.citizen_can_register;
          props.eventForm.registration.director_register = 0;
          props.eventForm.registration.admin_area_register = 0;
          break;
        default:
          break;
      }
    };

    const handleChangeMultiSelect = (value, select) => {
      switch (select) {
        case 'administrative_area_ids':
          if (value.includes(0)) {
            value = dataDOM.administrativesAreas.map(area => area.id_arbol);
            props.eventForm.all_areas = true;
          } else {
            props.eventForm.all_areas = false;
          }
          props.eventForm.administrative_area_ids = value;
          console.log(props.eventForm.administrative_area_ids);

          break;
        case 'work_center_ids':
          if (value.includes(0)) {
            value = dataDOM.workCenters.map(workCenter => workCenter.id);
            props.eventForm.all_work_areas = true;
          } else {
            props.eventForm.all_work_areas = false;
          }
          props.eventForm.work_center_ids = value;
          console.log(props.eventForm.work_center_ids);
          break;
        default:
          break;
      }
    };

    const filterOptionSelect = (input, option) => {
      return option.children[0]?.children?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0;
    };

    const handleSendFormEvent = () => {
      if (props.eventForm.generalInfo.type != 'benefits' && props.eventForm.generalInfo.type != 'other') {
        formRef.value
          .validate()
          .then(() => {
            if (validateCheckBoxAimetAt()) {
              dataDOM.visible = true;
              dataDOM.showMessageError = false;
              emit('getFormValues', { is_ok: true, typeForm: 'aimedAt' });
            } else {
              dataDOM.showMessageError = true;
              emit('getFormValues', { is_ok: false, typeForm: 'aimedAt' });
            }
          })
          .catch(e => {
            console.log(e);
            if (validateCheckBoxAimetAt()) {
              dataDOM.showMessageError = false;
            } else {
              dataDOM.showMessageError = true;
            }
            emit('getFormValues', { is_ok: false, typeForm: 'aimedAt' });
          });
      } else {
        emit('getFormValues', { is_ok: true, typeForm: 'aimedAt' });
      }
    };

    const validateCheckBoxAimetAt = () => {
      return (
        props.eventForm.admin_can_register ||
        props.eventForm.edu_can_register ||
        props.eventForm.student_can_register ||
        props.eventForm.citizen_can_register
      );
    };

    return {
      handleSendFormEvent,
      formRef,
      formState,
      rules,
      dataDOM,
      onChangeAimedAt,
      handleChangeMultiSelect,
      filterOptionSelect,
    };
  },
};

export default EventAimedAt;
</script>
<style scoped lang="sass">
.card
  background-color: #fff !important
  // border-radius: 15px
  padding: 1rem
  border-top: 0.5px solid #bdbdbd

.form-font
  font-family: Nutmeg
  &.title
    font-weight: bold
    padding: 0
.btn-fill-add
  border: 1px solid #3FA7E1
  color: #ffffff
  padding: 5px 15px 5px 15px
  background: #3FA7E1
  border-radius: 24px
.btn-disabled:hover
  cursor: not-allowed !important
button:hover
  cursor: pointer
.btn-outlined-exit
  border: 1px solid #636668
  color: #636668
  padding: 5px 15px 5px 15px
  background: none
  border-radius: 24px
  margin-left: 2rem
.ant-checkbox-checked
  background-color: #5F63F2 !important
  border-color: #5F63F2 !important
.ant-checkbox-inner
  background-color: #5F63F2 !important
  border-color: #5F63F2 !important
</style>
<style>
.ant-checkbox-checked {
  background-color: #3fa7e1;
  border-color: #3fa7e1;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #3fa7e1;
  border-color: #3fa7e1;
}
</style>
